if (document.getElementsByClassName('its--online-fix').length == 0) {
  window.onscroll = function () {
    scrollFunction();
  };
}

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById('bUp').style.display = 'block';
  } else {
    document.getElementById('bUp').style.display = 'none';
  }
}

function scrollBFunction() {
  window.scroll(0, -window.innerHeight);
}